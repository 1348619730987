import { render, staticRenderFns } from "./WeServed.vue?vue&type=template&id=289c4a6a&scoped=true&"
import script from "./WeServed.vue?vue&type=script&lang=js&"
export * from "./WeServed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289c4a6a",
  null
  
)

export default component.exports