<template>
    <div class="py-10 container-fluid">
        <div class="col-md-12"><h2 class="text-center text-white" style="font-size: 40px; margin: 50px 0px;">WE ARE PROUD TO HAVE YOU SERVED</h2></div>
        <div class="col-md-12 text-white">
            <div class="row">
                <div class="col-md-4 text-center" style="padding: 20px;">
                    <i class="fas fa-ticket-alt text-white" style="font-size: 70px;"> {{ticketSold}}+</i>
                    <p class="text-white p-5" style="font-size: 1.75rem;">TICKETS SOLD</p>
                </div>
                <div class="col-md-4 text-center" style="padding: 20px;">
                    <i class="fas fa-map-marked-alt text-white" style="font-size: 70px;"> {{stations ? stations.length : '20'}}+</i>
                  <p class="text-white p-5" style="font-size: 1.75rem;">DESTINATION SERVED</p>
                </div>
                <div class="col-md-4 text-center" style="padding: 20px;">
                    <i class="fa fa-bus text-white" style="font-size: 70px;"> {{companySummary ? companySummary.active_schedules : '300'}}+</i>
                  <p class="text-white p-5" style="font-size: 1.75rem;">TRIPS DAILY</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import etcService from '@/core/services/models/etc.service'

    export default {
        name: 'WeServed',
        data(){
            return{
                companySummary : null,
            }
        },
        props :["stations"],
        computed: {
          ticketSold: function (){
            let totalSold = this.companySummary ? this.companySummary.total_sold : '435';
            let totalSoldLen = totalSold.toString().length;
            let maxTens = 1;
            for(let i=2; i<totalSoldLen; i++){
              maxTens *= 10;
            }
            return Math.ceil(totalSold/maxTens)*maxTens;
          }
        },
        mounted(){
            etcService.getCompanySummaryInfo( s => {
                this.companySummary = s;
            }, e => {
                this.$toasted.error("Could not fetch company summary info")
            })
        }
    }
</script>

<style scoped>

</style>
